import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useMemo, useState } from "react";
import type { Update, UpdatesMeta } from "types";

import { fetchWrapper } from "@/lib/hooks/fetch-client";

interface UseUpdatesOptions {
	initialData: Update[];
	meta: UpdatesMeta;
	expertSlug?: string;
	pageSize: number;
	onError: () => void;
}

export function useUpdates({
	initialData,
	meta,
	expertSlug,
	pageSize,
	onError,
}: UseUpdatesOptions) {
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const [isLoading, setIsLoading] = useState(false);
	const [updates, setUpdates] = useState<Update[]>(initialData);

	const currentPage = useMemo(() => Number(searchParams.get("page")) || 1, [searchParams]);
	const hasMore = useMemo(
		() => currentPage < (meta?.pages || meta?.last_page),
		[meta?.pages, meta?.last_page, currentPage]
	);

	const updateQueryParams = useCallback(
		(newParams: Record<string, string>) => {
			const qs = new URLSearchParams({ ...Object.fromEntries(searchParams), ...newParams });
			router.push(`${pathname}?${qs.toString()}`, { scroll: false });
		},
		[router, pathname]
	);

	const loadMoreUpdates = useCallback(async () => {
		setIsLoading(true);

		try {
			const nextPage = currentPage + 1;

			const params = {
				...(searchParams.has("type") && { "type[]": searchParams.get("type") }),
				...(searchParams.has("category") && { "category[]": searchParams.get("category") }),
				page: String(nextPage),
				per_page: String(pageSize),
			};

			const basePath = expertSlug ? `/v1/content/experts/${expertSlug}` : "/v1/updates";
			const endpoint = `${basePath}?${new URLSearchParams(params)}`;

			let moreUpdates = [];

			if (expertSlug) {
				const data = await fetchWrapper<{ contributions: Update[]; meta: UpdatesMeta }>(endpoint, {
					next: { tags: ["updates"], revalidate: 86400 },
				});

				moreUpdates = [...data.contributions];
			} else {
				const data = await fetchWrapper<{ data: Update[] }>(endpoint, {
					next: { tags: ["updates"], revalidate: 86400 },
				});

				moreUpdates = [...data.data];
			}

			setUpdates((prev) => [...prev, ...moreUpdates]);
			updateQueryParams({ page: String(nextPage) });
		} catch {
			onError();
		} finally {
			setIsLoading(false);
		}
	}, [currentPage, pageSize, searchParams, expertSlug, updateQueryParams, onError]);

	return {
		updates,
		isLoading,
		hasMore,
		loadMoreUpdates,
		updateQueryParams,
	};
}
